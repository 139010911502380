import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/Home',
    name: "Home",
    redirect: "/index",
    component: () => import('@/views/Home.vue'),

    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/DataOverview/Dataoview.vue'),
        meta: { menuText: ['数据概览'] }
      },
      {
        path: '/registration',
        name: 'registration',
        component: () => import('../views/IotManage/registration.vue'),
        meta: { menuText: ['物联管理', '物联注册'] }
      },
      {
        path: '/gateway',
        name: 'gateway',
        component: () => import('../views/IotManage/gateway.vue'),
        meta: { menuText: ['物联管理', '网关'] }
      },
      {
        path: '/alarmrules',
        name: 'alarmrules',
        component: () => import('../views/OperationManage/Alarmrules.vue'),
        meta: { menuText: ['运维管理', '报警规则'] }
      },
      {
        path: '/alarmevent',
        name: 'alarmevent',
        component: () => import('../views/OperationManage/Alarmevent.vue'),
        meta: { menuText: ['运维管理', '报警事件'] }
      },
      {
        path: '/loginLog',
        name: 'loginLog',
        component: () => import('../views/LogManage/loginLog.vue'),
        meta: { menuText: ['日志记录','登录日志'] }
      },
      {
        path: '/opLog',
        name: 'opLog',
        component: () => import('../views/LogManage/opLog.vue'),
        meta: { menuText: ['日志记录','操作日志'] }
      },
      {
        path: '/systemLog',
        name: 'systemLog',
        component: () => import('../views/LogManage/systemLog.vue'),
        meta: { menuText: ['日志记录','系统异常日志'] }
      },

      {
        path: '/meterManage',
        name: 'MeterManage',
        component: () => import('@/views/meter/meterManage.vue'),
        meta: { menuText: ['物联管理', '表计概览'] }
      },
      {
        path: '/offLog',
        name: 'OffLog',
        component: () => import('@/views/meter/offLog.vue'),
        meta: { menuText: ['物联管理', '状态记录'] }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
