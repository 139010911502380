function storage(action,key,data){
    let back=""
    if(action=='set'){
         if(typeof data=='object'){
             window.localStorage.setItem(key,JSON.stringify(data))
         }else{
            window.localStorage.setItem(key,data)
         }
    }else if(action =='get'){
        let get=window.localStorage.getItem(key)
        back=JSON.parse(JSON.stringify(get))
        return back
    }
}

export default { storage }