import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/common.css"

Vue.config.productionTip = false

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)

import Storage from "./components/strorage"
Vue.prototype.$Storage=Storage

 // 将封装的 请求方法 挂载为全局方法, 方便使用
 import requests from "@/utils/request.js"
 Vue.use(requests);
 Vue.prototype.$requests = requests;

 import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)
// import {
//   DEFAULT_MULTI_PAGE
// } from "@/store/mutation-types"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
